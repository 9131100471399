import { css } from 'styled-components';
import { themeVal, media } from '@devseed-ui/theme-provider';

export const callout = () => css`
  font-family: ${themeVal('type.heading.family')};
  text-transform: ${themeVal('type.heading.case')};
  font-weight: ${themeVal('type.heading.weight')};
  font-style: ${themeVal('type.heading.style')};
  font-variation-settings: ${themeVal('type.heading.settings')};
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0;

  ${media.smallUp`
    font-size: 2.5rem;
    line-height: 3rem;
  `}

  ${media.mediumUp`
    font-size: 3rem;
    line-height: 3.5rem;
  `}

  ${media.largeUp`
    font-size: 3.5rem;
    line-height: 4rem;
  `}

  ${media.xlargeUp`
    font-size: 4rem;
    line-height: 4.5rem;
  `}

  a {
    box-shadow: inset 0 -8px 0 0 ${themeVal('color.primary')};

    &,
    &:visited {
      color: inherit;
    }
  }
`;
