import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { media, glsp, visuallyHidden } from '@devseed-ui/theme-provider';

import Layout from '../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../styles/inpage';
import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionSuptitle,
  SectionLead
} from '../styles/section';
import Media from '../components/media';
import Gridder from '../styles/gridder';
import { callout } from '../styles/typography/callout';
import SectionLatestWork from '../components/latest-work';

import MediaHomeCover from '../media/layout/home/home-cover--largeup.jpg';
import { SectionWhatWeDo } from './home/_section-what-we-do';

export const ActLang = styled(Gridder).attrs({ as: 'section' })`
  padding-top: ${glsp()};
  padding-bottom: ${glsp()};
  align-items: end;

  ${media.smallUp`
    padding-top: ${glsp(2)};
    padding-bottom: ${glsp(2)};
  `}
`;

export const ActLangTitle = styled.h1`
  ${visuallyHidden};
`;

export const ActLangBody = styled.div`
  ${callout};
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-column: content-2 / content-8;
  `}

  ${media.largeUp`
    grid-column: content-2 / content-12;
  `}

  ${media.xlargeUp`
    grid-column: content-3 / content-11;
  `}

  > * {
    margin-bottom: 0;
  }

  a,
  a:not([class]) {
    &,
    &:visited {
      color: inherit;
    }
  }
`;

export const WhatSectionHeadline = styled(SectionHeadline)`
  ${media.largeUp`
    grid-column: content-2 / content-8;
  `}

  ${media.xlargeUp`
    grid-column: content-2 / content-7;
  `}
`;

const Home = ({ data, location }) => {
  const siteDescription = data.site.siteMetadata.description;

  const expertiseList = data.expertise.edges;

  return (
    <Layout location={location} title='Welcome'>
      <Inpage as='section'>
        <InpageHeader leadLayout='aside'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{siteDescription}</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                Geospatial solutions and global insights for a complex and
                changing planet.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <Media
            size='cover'
            decoration='bottom'
            src={MediaHomeCover}
            alt='Cover'
            attribution={{
              name: 'Iban Ameztoy',
              url: 'https://www.flickr.com/photos/184249102@N03/48810018258/'
            }}
          />

          <Section>
            <SectionHeader leadLayout='aside'>
              <WhatSectionHeadline>
                <SectionTitle>
                  Working on the world&apos;s hardest geospatial problems.
                </SectionTitle>
                <SectionSuptitle>What we do</SectionSuptitle>
              </WhatSectionHeadline>
              <SectionLead>
                <p>
                  We&apos;re not scared by big problems. We rely on our
                  engineering led processes, tailored open source solutions, and
                  commitment to success in order to create change at a global
                  scale.
                </p>
              </SectionLead>
            </SectionHeader>
          </Section>

          <SectionWhatWeDo expertiseList={expertiseList} />

          <SectionLatestWork />

          <ActLang>
            <ActLangTitle>In a nutshell</ActLangTitle>
            <ActLangBody>
              <p>
                Learn more about{' '}
                <Link to='/company' title='View page'>
                  who we are
                </Link>
                ,{' '}
                <Link to='/how-we-work' title='View page'>
                  what drives us
                </Link>
                , and{' '}
                <Link to='/blog' title='View page'>
                  what we&apos;re currently working on
                </Link>
                .
              </p>
            </ActLangBody>
          </ActLang>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Home;

Home.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

//                                                                            //
// END   SectionExpertise - Auxiliary components                              //
// // // // // // // // // // // // // // // // // // // // // // // // // // //

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }

    expertise: allExpertise(
      filter: { published: { eq: true } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          ...ExpertiseCardData
        }
      }
    }
  }
`;
