import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Masonry } from 'masonic';
import get from 'lodash.get';
import styled, { css } from 'styled-components';
import { media } from '@devseed-ui/theme-provider';

import {
  FoldInner,
  FoldHeader,
  FoldSuptitle,
  FoldLead,
  FoldBody,
  FoldTitle,
  Fold
} from '../../styles/fold';
import { CardExpertise } from '../../components/cards';
import { MasonryItemsListElement } from '../../styles/masonry';
import useMediaAware from '../../utils/media-query-aware-hook';

const Inner = styled.div`
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-column: content-2 / content-end;
  `}
`;

// Randomize heights on build so the cards have some height variability.
const randomHeights = ['18rem', '34rem', '30rem', '25rem', 'auto'];

const CardExpertiseMixHeight = styled(CardExpertise)`
  ${({ index }) => {
    const v = randomHeights[index % randomHeights.length];

    return css`
      min-height: ${v};
      height: ${v};
    `;
  }};
`;

const masonryItemsRenderFn = ({ index, data }) => {
  const { slug } = data.node;
  const { title, media, teaser } = data.node.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  return (
    <CardExpertiseMixHeight
      title={title}
      slug={slug}
      cardImage={cardImage}
      description={teaser}
      index={index}
      skin='dark'
    />
  );
};

export const SectionWhatWeDo = (props) => {
  const { expertiseList } = props;

  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  return (
    <Fold>
      <FoldInner>
        <FoldHeader>
          <FoldSuptitle>Accelerate a better tomorrow</FoldSuptitle>
          <FoldTitle>What we do</FoldTitle>
          <FoldLead>
            <p>
              Our partners use our tools to make better decisions, smarter
              investments, and plan a better future. Our tools deliver streaming
              insights on the planet and populations, delivered when, where, and
              how decisions are made.
            </p>
          </FoldLead>
        </FoldHeader>
        <FoldBody>
          <Inner>
            <Masonry
              items={expertiseList}
              overscanBy={Infinity}
              columnGutter={
                media
                  ? media.isXsmallDown()
                    ? 16
                    : media.isLargeDown()
                    ? 32
                    : 48
                  : null
              }
              columnCount={media ? (media.isSmallDown() ? 1 : 2) : null}
              columnWidth={288}
              as={MasonryItemsListElement}
              itemAs='li'
              itemKey={(item) => item.node.id}
              render={masonryItemsRenderFn}
            />
          </Inner>
        </FoldBody>
      </FoldInner>
    </Fold>
  );
};

SectionWhatWeDo.propTypes = {
  expertiseList: PropTypes.array
};
